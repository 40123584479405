import { useGate } from "@nestoca/gate-react";
import { useTenant } from "@nestoca/multi-tenant";
import { useTranslation } from "react-i18next";

export const useGetPageTitle = () => {
  const tenant = useTenant();
  const { t } = useTranslation("dashboard");

  const gate = useGate();
  const isExternalBroker = gate.getRole() === "externalbroker";

  const extBrokerPageTitle: Record<string, string> = {
    "*": t("partnersHubTitle"),
    cmls: t("cmlsHubTitle"),
  };

  const getPageTitle = () => {
    if (isExternalBroker) {
      return (
        (tenant?.slug && extBrokerPageTitle[tenant.slug]) ||
        extBrokerPageTitle["*"]
      );
    }
    return t("financialDashboardTitle");
  };

  return getPageTitle();
};
